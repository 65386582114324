.default {
  h2, h3, h4, h5, h6 {
    a {
      color: $dark-blue !important;
      text-decoration: none;
      font-weight: 600;
      &:hover, &:focus, &:active {
        color: $link-hover !important;
        text-decoration: underline;
      }
    }
  }

  .toc a {
    color: $black !important;
    text-decoration: none;

    &:hover, &:focus, &:active {
      color: $link-hover !important;
      text-decoration: underline;
    }
  }
}
