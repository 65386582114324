// PROJECT color palette

$yellow: #FFC000;
$orange: #FF8900;
$dark-orange: #B57100;
$blue: #53B9D7;
$light-blue: #DCF1F7;
$dark-blue: #182C46;

// Other colors 
$link : #1F579C;
$link-visited : #5F00A2;
$link-hover : #FF8900;

$link-dark-bg : #98D5E7;
$link-visited-dark-bg : #E49EFF;
$link-hover-dark-bg : #FF8900;
