.download-announcement-band {
      .ctas {
      margin-bottom: 4rem;
    }

    .icons {
      a > i {
        color: $blue !important;
        margin-bottom: 3rem;
        text-decoration: none;
      }
      p > a {
        font-size: 2.5rem;
        font-weight: 200;
        color: $dark-blue !important;
        text-decoration: none;
      }
    }
}
