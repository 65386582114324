// This is a partial.
// It lies in <source>/_sass, just waiting to be imported.
// It does not contain the YAML front matter and has no corresponding output file in the built site.

.homepage {
  .video-content-band {
    p, ul li {
      font-size: 1.2rem;
      line-height: 1.75rem;
    }
  }
  .video-container {
    @extend .align-self-center;
  }
}
