.blog,
.post {
  .blog-list-item {
    margin-bottom: 3rem;

    * {
      margin: 0;
    }
  }
  .post-content {
    min-width: 0;
  }
}
