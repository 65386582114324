.share-page {
  a {
    text-decoration: none;
    img {
      max-width: 1.5rem;
      margin-left: .5rem;
      margin-bottom: -.35rem;
      padding-top: .35rem;
    }
  }
}
