.download-release-band {
  table {
    border: 1px solid $blue;
    border-collapse:collapse;
    th {
      background-color: $blue;
      padding: 1rem;
      .version-name, .release-date {
        color: $white;
        font-weight: 400;
        font-size: 1.2rem;
      }
    }
    tbody tr {
      background-color: transparent;
      td {
        border-bottom: 1px solid $blue;
      }
    }
  }
}
