$baseurl: "";

/* import nunito font and set initial weight at 400 */
@import url('https://fonts.googleapis.com/css?family=Nunito:400,700&display=swap');

@import "core/global";
@import "core/grid";
@import "core/colors";
@import "core/asciidoc";
@import "core/includes/product-download-band";
@import "core/includes/quick-pitch-band";
@import "core/includes/secondary-page-title-band";
@import "core/includes/download-announcement-band";
@import "core/includes/two-column-content-band";
@import "core/includes/mid-page-cta-band";
@import "core/includes/docs-archive-subnav";
@import "core/includes/contributions-band";
@import "core/includes/big-three-subnav-band";
@import "core/includes/video-content-band";
@import "core/includes/highlights-inline-images-band";
@import "core/includes/highlights-alternating-images-band";
@import "core/includes/highlights-three-columns-band";
@import "core/includes/download-release-band";
@import "core/includes/supported-versions-band";
@import "core/includes/contact-form-band";

@import "project-colors";
@import "components";
@import "strimzi";
@import "layouts/blog";
@import "layouts/home";
@import "layouts/default";
@import "includes/project-footer";
@import "includes/redhat-footer";
@import "includes/share-page";
@import "includes/community-contributions-band";
@import "includes/strimzi_navigation";
@import "includes/download-release-band";
@import "includes/supported-versions-band";
@import "includes/download-archives-band";
@import "includes/presentation-cards-band";
@import "includes/homepage-download-announcement-band";
@import "includes/documentation-resources-band";
@import "includes/tabs";
@import "includes/toc";
