.project-footer,
.site-content.project-footer {
  @extend .dark-bg;
  @extend .component-slim;

  background-color: $black;
  padding-bottom: 2rem !important;

  .logo-wrapper {
    text-align: center;
    display: block;
    margin: 0 auto;
    .project-logo {
      max-width: 18rem;
      @media screen and (max-width: 768px) {
        max-width: 10rem;
      }
    }
  }

  p.copyright-strimzi {
    margin-top: 1rem;
  }

  p.copyright-cncf {
    font-size:0.8rem;
    margin-top: 2rem;
  }

  p {
    margin-top: 0;
    margin-bottom: 0;
    a {
      color: $link-dark-bg !important;
      &:hover, &:hover i {
        color: $link-hover-dark-bg !important;
      }
    }
  }

}
