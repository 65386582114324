/* For smaller mobile devices */

@media only screen and (max-width: 769px) {

  .toc-left {
    display: none !important; 
  }

  #toctitle {
    display: none;
  }

  .toggle {
    display: none !important;
  }

  .doc-content {
    width: 95% !important;
    margin-top: 136px;
    padding-left: 1rem;
    overflow-x: hidden;
  }

  .doc-content .toc {
    display: contents;
    list-style: none;
  }

  .toc ul {
    list-style: none;
  }

  .toc ol {
    display: grid;
  }

  h1 {
    font-size: 2rem;
  }

  h2 {
    font-size: 1.8rem;
  }

  h3 {
    font-size: 1.6rem;
  }

  h4 {
    font-size: 1.4rem;
  }

  h5 {
    font-size: 1.2rem;
  }
}

/* For larger mobile devices */

@media only screen and (min-width: 769px) and (max-width: 1023px) {

  .toc-left {
    display: none !important; 
  }

  #toctitle {
    display: none;
  }

  .toggle {
    display: none !important;
  }

  .doc-content {
    width: 95% !important;
    margin-top: 128px;
    padding-left: 1rem;
    overflow-x: hidden;
  }

  .doc-content .toc {
    display: contents;
    list-style: none;
  }

  .toc ul {
    list-style: none;
  }

  .toc ol {
    display: grid;
  }

  h1 {
    font-size: 2rem;
  }

  h2 {
    font-size: 1.8rem;
  }

  h3 {
    font-size: 1.6rem;
  }

  h4 {
    font-size: 1.4rem;
  }

  h5 {
    font-size: 1.2rem;
  }
}

/* For tablet devices */

@media only screen and (min-width: 1024px) and (max-width: 1450px) {

  .doc-content {
    float: right;
    padding-right: 1rem;
    padding-left: 3rem;
    overflow-x: hidden;
    box-sizing: border-box;
    width: 75%
  }

  .doc-content .toc {
    display: none;
  }

  h2 {
    font-size: 2rem;
  }

  h3 {
    font-size: 1.8rem;
  }

  h4 {
    font-size: 1.6rem;
  }

  h5 {
    font-size: 1.4rem;
  }

}

/* For desktop devices */

@media only screen and (min-width: 1450px) {

  .doc-content {
    float: right;
    padding-right: 1rem;
    padding-left: 3rem;
    overflow-x: hidden;
    box-sizing: border-box;
    width: 75%
  }

  .doc-content .toc {
    display: none;
  }

  h2 {
    font-size: 2.2rem;
  }

  h3 {
    font-size: 2rem;
  }

  h4 {
    font-size: 1.8rem;
  }

  h5 {
    font-size: 1.6rem;
  }

}

/* navigation formats */

.main-nav .container {
  padding-right: 2rem;
  padding-left: 2rem;
}

/* navigation header */


.doc-nav {

  @media screen and (max-width: 1450px) {
    padding: 0 0rem;
    min-width: max-content;
    position: fixed;
    width: 100%;
    top: 0;
    background-color: white;
    z-index: 1;
  }

  @media screen and (min-width: 1450px) {
    padding: 0 0rem;
    min-width: max-content;
    position: fixed;
    width: 100%;
    top: 0;
    background-color: white;
    z-index: 1;
  }
}

/* link targets */

/* mobile */
@media only screen and (max-width: 1024px) {
 
  /* scroll down from top for links from ToC*/
  .doc-content h2:target,
  .doc-content h3:target,
  .doc-content h4:target {
    scroll-margin-top: 162px;
  }

}

@media only screen and (min-width: 1024px) and (max-width: 1450px) {

  .toc-left {
    margin-top: 210px;
    height: calc(100% - 210px);
  }


  .toc-left.footer-visible {
    height: calc(100% - 528px)!important;
    overflow-y: auto;
  }

  h1 {
    font-size: 3rem;
    padding-top: 196px;
  }

  /* scroll down from top for links from ToC*/
  .doc-content h2:target, 
  .doc-content h3:target,
  .doc-content h4:target {
    scroll-margin-top: 222px;
  }

}

@media only screen and (min-width: 1450px) {

  .toc-left {
    margin-top: 150px;
    height: calc(100% - 160px);
  }


  .toc-left.footer-visible {
    height: calc(100% - 460px);
    overflow-y: auto;
  }

  h1 {
    font-size: 3rem;
    padding-top: 136px;
  }

  /* scroll down from top for links from ToC*/
  .doc-content h2:target, 
  .doc-content h3:target,
  .doc-content h4:target {
    scroll-margin-top: 164px;
  }
}

/* left toc general formats */

.toc-left {
  position: fixed;
  float: left;
  width: 25%;
  overflow: auto;
  z-index: 0;
  box-sizing: border-box;
  font-size: 1rem;
}

.toc-left ul {
  list-style: none;
  padding: 0 0 0 0.25rem;
  word-wrap: break-word;
}

.toc-left li {
  margin: 0;
}

.toc-left a {
  display: block;
  padding: 1px 1px;
  color: #182C46 !important;
  text-decoration: none;
  transition: all 0.2s;
}

ul.sectlevel1 li a.link {
  font-weight: bold !important;
}

/* makes top section heading bold in ToC */
.toc_heading_2>a:first-child {
  font-weight: bold;
}

li>ul {
  margin-left: 16px;
}

.toc-left li a:hover {
  color: $orange !important;
}

.footer {
  position: relative;
  clear: both;
} 

/* scroll to top button settings */

#topBtn {
  display: none;
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 99;
  border: none;
  outline: none;
  background-color: #ccc;
  color: white;
  cursor: pointer;
  padding: 12px;
  border-radius: 12px;
  font-size: 1rem;
}

#topBtn:hover {
  background-color: #555 !important;
}

/* ToC toggle settings */

.toggle-label {
  text-transform: uppercase;
  font-size: 0.9rem;
  display: inline-block;
  color: #2A5D6C;
  font-weight: bold;
}

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 49px;
  height: 25px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 21px;
  width: 21px;
  left: 4px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #ccc;
}

input:focus + .slider {
  box-shadow: 0 0 1px #ccc;
}

input:checked + .slider:before {
  -webkit-transform: translateX(21px);
  -ms-transform: translateX(21px);
  transform: translateX(21px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 25px;
}

.slider.round:before {
  border-radius: 50%;
}

/* aligns ToC menu elements */
.toc-nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #ffffff;
  border-bottom: 1px solid #dadfe6;
  padding-bottom: 2px;
  padding-right: 10px;
  padding-left: 20px;
  height: 45px;
}

