@mixin position($pos: relative, $top: null, $right: null, $bottom: null, $left: null) {
  position: $pos;
  top: $top;
  right: $right;
  bottom: $bottom;
  left: $left;
}

div.nav-wrapper {
  padding: 0 6rem;
  @media screen and (max-width: 1440px) { padding: 0 4rem; }
  @media screen and (max-width: 1072px) { padding: 0 2rem; }
}

.main-nav {
  position: relative;
  width: 100%;
  padding-top: 1rem;

  .container {
    padding-top: 1rem;
  }

  a, a i {
    font-size: 1.2rem;
    line-height: 1.8rem;
    text-transform: uppercase;
    color: $white !important;
    font-weight: 600;
    text-decoration: none;
    &.active{
      color: $orange !important;
      position: relative;
    }
  }

  .logo-wrapper {
    display: inline-block;
    padding-top: 0; 
    width: 13rem;
    height: 40px;
    padding: 0 10px;
    z-index: 0;
    @media screen and (max-width: 768px) {
      width: 8rem;
      margin-top: .5rem;
    }

    @include position;

    a {
      color: #2A5D6C !important;
    }
  }

  .menu {
    background: $dark-blue;
    border: 1px solid #ffffff2e;
    display: none;
    list-style: none;
    margin: 0;
    padding: 0;
    text-align: center;
    z-index: 1;

    @include position(absolute, 111px, 0, null, 0);

    a {
      border-bottom: 1px solid #ffffff2e;
      font-weight: bold;
      display: block;
      padding: 15px;

      &:hover, &:hover i {
        color: $orange !important;
      }
    }
  }

  .nav-toggle {
    display: inline-block;
    font-size: 1.7em;
    line-height: 1.5em;
    float: right;
    user-select: none;
    -webkit-user-select: none;
    i {
      color: $dark-blue;
      vertical-align: middle;
    }
  }
}

#checkbox {
  visibility: hidden;
  opacity: 0;
  position: absolute;
  top: -99999px;

  &:checked ~ nav .menu {
    display: block;
  }
}

@media screen and (min-width: 1024px) {
  .main-nav {
    overflow: hidden;
    .nav-toggle {
      display: none;
    }

    .container {
      padding-top: 0;
      padding-bottom: 0;
    }

    .logo-wrapper {
      margin: 5px 0;
    }

    .menu {
      display: block;
      box-shadow: none;
      border: none;
      float: right;
      background-color: $white;

      @include position(static);

      li {
        display: inline-block;
      }

      span {
        display: inline-block;
        vertical-align: middle;
      }

      a {
        color: #2A5D6C !important;
        border: none;
        padding: 20px 10px;
        height: 35px;
        &.active {
          color: $orange !important;
        }
      }
      a, a i {
        color: #2A5D6C !important;
      }
    }
  }
}

