.download-archives-band {
  ul {
    list-style: none;
    padding-inline-start: 0;
    li a { line-height: .5rem; }
  }
  
  h3 { margin: 0; }

  i {
    text-decoration: none;
  }
}
