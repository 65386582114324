.presentation-cards-band {
  @extend .full-width-bg;
  background-color: #98D5E7;
  
  .card {
    background-color: $white;
    padding: 2rem;

    ul {
      padding-inline-start: 0;
      li {
        display: inline;
        &:not(:first-child):before {
          content: " | "
        }
        a, a i { text-decoration: none; }
      }
    }
  }
}
