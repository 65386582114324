/** Styles for _includes/templates/tabs.html */
.tabs {
  display: flex;
  flex-wrap: wrap;
  margin: 2rem 0;
}
.tabs label {
  order: 1;
  display: block;
  padding: 2rem;
  margin-right: 0.2rem;
  cursor: pointer;
  background-color: #A4A4A4;
  color: $white;
  font-size: 1.5rem;
  line-height: 2rem;
  font-weight: 700;
  transition: background ease 0.2s;
}
.tabs .tab {
  order: 99;
  flex-grow: 1;
  width: 100%;
  display: none;
  padding: 2rem;
  background-color: $white;
  border: 1px solid #707070;
}
.tabs input[type="radio"] {
  display: none;
  &:checked + label {
    background-color: $white;
    color: $dark-blue;
    border: 1px solid #707070;
    border-bottom: none;
    position: relative;
    top: 1px;
  }
  &:checked + label + .tab {
    display: block;
    overflow: scroll;
  }
}

@media (max-width: 45em) {
  .tabs .tab,
  .tabs label {
    order: initial;
  }
  .tabs label {
    width: 100%;
    margin-right: 0;
    margin-top: 0.2rem;
  }
}
