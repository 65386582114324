// PROJECT styles to overwrite global.scss

html,
body,
*
{
  font-size: 100%;
  font-weight: 100;
  font-family: "Nunito", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
}

body {
  font-size: 1.1rem;
  line-height: 1.5rem;
}

body:not(.homepage) {
  div.site-content {
    padding-bottom: 6rem;
  }
}

.container__usedby{
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  
  @media only screen and (max-width: 768px){
    flex-direction: column;
    align-items: center;
    margin: 3rem 3rem;
  }
}

.item__usedby{
  display: flex;
  align-items: center;
  flex: 0 0 30%;
  max-width: 300px;
  width: 100%;
  margin-bottom: 5rem;

  @media only screen and (min-width: 1616px){
    padding: 1rem 1rem;
  }
}

.container__cnative{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.cnative__icon{
  max-width: 300px;
  padding: 20px 0;
}

.cnative__text{
  text-align: center;
}

.grid-wrapper,
.component-wrapper {
  grid-gap: 2em;
  grid-row-gap: 1em;
  @media screen and (max-width: 768px) {
    grid-gap: 0;
  }
}

h1 {
  font-size: 4rem;
  line-height: 4rem;
  font-weight: 800;
  color: $dark-blue;
  @media screen and (max-width: 768px) {
    font-size: 2rem;
    line-height: 3rem;
  }
}

h2 {
  font-size: 3.75rem;
  line-height: 1.75rem;
  font-weight: 600;
  color: $dark-blue;
  @media screen and (max-width: 768px) {
    font-size: 1.5rem;
    line-height: 2rem;
  }
}

h3 {
  font-size: 2.5rem;
  line-height: 1.75rem;
  font-weight: 400;
  color: $dark-blue;
  @media screen and (max-width: 768px) {
    font-size: 1.2rem;
    line-height: 1.7rem;
  }
}

h4 {
  font-size: 1.75rem;
  line-height: 1.75rem;
  font-weight: 400;
  color: $dark-blue;
  @media screen and (max-width: 768px) {
    font-size: 1.125rem;
    line-height: 1.5rem;
  }
}

h5 {
  font-size: 1.5rem;
  line-height: 1.75rem;
  font-weight: 400;
}

h6 {
  font-size: 1.1rem;
  line-height: 1.75rem;
  font-weight: 700;
}

p {
  font-size: 1.1rem;
  line-height: 1.5rem;
  margin: 0 0 1rem;
}

a, a i {
  line-height: 1.5rem;
  font-weight: 400;
  cursor: pointer;
  text-decoration: underline;
  color: $link !important;
  &:visited {
    color: $link-visited;
  }
  &:hover {
    color: $link-hover !important;
  }
}

a.button-cta,
button,
input[type="submit"] {
  color: $white !important;
  background-color: $orange;
  border: 2px solid $orange;
  font-weight: 600;
  font-size: 1.2rem;
  line-height: 1.444;
  display: inline-block;
  white-space: normal;
  &:hover, &:active, &:focus {
    background-color: $dark-orange !important;
    border-color: $dark-orange !important;
    color: #FFC000;
  }

  @media screen and (max-width: 768px) {
    font-size: 1rem;
  }
}

code {
  background-color: #F0F0F0;
  padding: 0 5px;
  color: inherit;
  overflow-wrap: break-word;
}

p code,
li code {
  font-size: 0.9em;
  line-height: 1.5;
}

a > code,
table code,
dt code {
  color: inherit;
  background-color: transparent;
  padding: 0;
}

dt {
  font-weight: 600;
}

em {
  color: inherit;
}

hr {
  border-width: 1px;
  border-color: $dark-blue;
}

ul {
  padding-inline-start: 20px;
  li > p,
  li {
    margin: 0.5em 0;
  }
}

table {
  caption {
    font-size: 1.3rem;
    text-align: left;
    font-weight: 600;
    margin-bottom: 12px;
  }
  thead th {
    background-color: $dark-blue;
    font-size: 1.2rem;
    line-height: 1.5rem;
    font-weight: 600;
  }
}

blockquote {
  width: 80%;
  margin: 2rem auto;
  font-style: italic;
  padding: 1.2rem 1.5rem 1.2rem 3rem;
  border-left: 5px solid $blue;
  line-height: 1.6;
  position: relative;
  background: #F9F9F9;

  p:last-child {
    margin-bottom: 0;
  }
}

blockquote::before {
  content: "\201C";
  color: $blue;
  font-size: 4em;
  position: absolute;
  left: 10px;
  top: -10px;
}

blockquote::after {
  content: '';
}

.full-width-bg {
  margin: 0 -13rem;
  padding: 0 13rem;
  @media screen and (max-width: 1170px) {
    margin: 0 -6rem;
    padding: 0 6rem;
  }
  @media screen and (max-width: 768px) {
    margin: 0 -2rem;
    padding: 0 2rem;
  }
}

.component {
  padding-top: 4rem;
  padding-bottom: 6rem;
}

.component-slim {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

// Changes type to readable color on a dark background
.dark-bg {
  background-color: $dark-blue;
}

.gradient-bg {
  background: rgb(24, 44, 70);
  background: -moz-linear-gradient(
    145deg,
    rgba(24, 44, 70, 1) 0%,
    rgba(39, 81, 108, 1) 50%,
    rgba(83, 185, 215, 1) 100%
  );
  background: -webkit-linear-gradient(
    145deg,
    rgba(24, 44, 70, 1) 0%,
    rgba(39, 81, 108, 1) 50%,
    rgba(83, 185, 215, 1) 100%
  );
  background: linear-gradient(
    145deg,
    rgba(24, 44, 70, 1) 0%,
    rgba(39, 81, 108, 1) 50%,
    rgba(83, 185, 215, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#182c46",endColorstr="#53b9d7",GradientType=1);
}

.dark-bg, .gradient-bg {
  *, p {
    color: $white;
  }

  h2 {
    color: $yellow;
  }

  a, a i {
    color: $link-dark-bg !important;
    &:visited {
      color: $link-visited-dark-bg !important;
    }
    &:hover {
      color: $link-hover-dark-bg !important;
    }
  }
}

.blue {
  color: $dark-blue;
}

.blue-bg {
  background-color: $blue;
  *, p, a {
    color: $white !important;
  }
}

.pattern-wrapper {
  @extend .full-width-bg;
  background-image: url($baseurl + '/assets/images/dark_hero_pattern.png');
  background-repeat: repeat;
  background-size: auto;
  background-position: top right;
}

.pattern-wrapper-light {
  @extend .full-width-bg;
  background-image: url($baseurl + '/assets/images/light_hero_pattern.png');
  background-repeat: repeat-x;
  background-size: cover;
  background-position: top right;

  h3 {
    font-size: 2rem;
    line-height: 2.75rem;
  }
}

.text-centered {
  text-align: center;
}

.text-caps {
  text-transform: uppercase;
}

.hide-mobile {
  @media screen and (max-width: 768px) {
    display: none;
  }
}

.admonitionblock {
  margin: 1rem 0;
  .title {
    text-transform: uppercase;
  }
}

.admonitionblock.note, .note {
  tbody .icon {
    background-color: $blue;
  }
  tbody .content {
    background-color: $light-blue;
  }
}

.admonitionblock.warning, .warning {
  tbody .icon {
    background-color: $yellow;
  }
  tbody .content {
    background-color: #FFEEBC;
  }
}

.admonitionblock.important, .important {
  tbody .icon {
    background-color: #E44B4B;
  }
  tbody .content {
    background-color: #FFD9D9;
  }
}

.admonitionblock.tip, .tip {
  tbody .icon {
    background-color: #46B29D;
  }
  tbody .content {
    background-color: #E6F6F3;
  }
}

.justify-self {
  &-start {
    justify-self: start !important;
  }
  &-center {
    justify-self: center !important;
  }
  &-end {
    justify-self: end !important;
  }
}

.align-self {
  &-start {
    align-self: start !important;
  }
  &-center {
    align-self: center !important;
  }
  &-end {
    align-self: end !important;
  }
}

.alt-background {
  background-color: $light-blue !important;
}

.margin-tb-0 {
  margin-top: 0;
  margin-bottom: 0;
}

.margin-tb-sm {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.margin-tb-md {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.margin-tb-lg {
  margin-top: 4rem;
  margin-bottom: 4rem;
}

.margin-tb-xl {
  margin-top: 5rem;
  margin-bottom: 5rem;
}

.padding-tb-0 {
  padding-top: 0;
  padding-bottom: 0;
}

.padding-tb-sm {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.padding-tb-md {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.padding-tb-lg {
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.padding-tb-xl {
  padding-top: 5rem;
  padding-bottom: 5rem;
}

.post,
.toc-content {
  h1 {
    font-size: 3rem;
    line-height: 3rem;
  }

  h2 {
    font-size: 2.75rem;
    line-height: 2.5rem;
  }
  h3 {
    font-size: 2rem;
    line-height: 3rem;
    code {
      font-size: 2.5rem;
    }
  }
}

.title {
  font-weight: 600;
}

.paragraph > .title {
  font-size: 1.3rem;
  text-align: left;
  margin-bottom: 12px;
}

.announcement_blue, .announcement_orange {
  margin: 0 -13rem;
  padding: 2rem 13rem;

  @media screen and (max-width: 1170px) {
    margin: 0 -6rem;
    padding: 2rem 6rem;
  }

  @media screen and (max-width: 768px) {
    margin: 0 -2rem;
    padding: 2rem;
  }

  p {
    font-size: 1.5rem;
    line-height: 1.75rem;
    text-align: center;
    margin: .5rem;
    color: $black;
  }

  a {
    color: $black !important;
  }
}

.announcement_blue {
  background-color: $light-blue;
}
.announcement_orange {
  background-color: $orange;
}

/* Zoom band */

.zoom_band {
  background-color: $light-blue;
  margin: 0 -13rem;
  padding: 2rem 13rem;

  @media screen and (max-width: 1170px) {
    margin: 0 -6rem;
    padding: 2rem 6rem;
  }

  @media screen and (max-width: 768px) {
    margin: 0 -2rem;
    padding: 2rem;
  }

  p {
    font-size: 1.5rem;
    line-height: 1.75rem;
    margin: .5rem;
    color: $black;
  }

  img {
    max-width: 150px;
    float: right;
  }
}

//styles for doc tiles

.grid-container {
  display: grid;
  gap: 20px;
  margin-top: 15px;
  grid-template-columns: repeat(3, minmax(250px, 1fr));
}

@media (max-width: 900px) {
  .grid-container {
    grid-template-columns: repeat(2, minmax(250px, 1fr));
  }
}

@media (max-width: 600px) {
  .grid-container {
    grid-template-columns: repeat(1, minmax(250px, 1fr));
  }
}

.tile {
  background: #f8f9fa;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-align: center;
  text-decoration: none;
  display: block;
  color: inherit;
  position: relative;
}

.tile:hover {
  background: #FFFFFF;
}

.tile h6 {
  margin-bottom: 8px;
  text-align: left;
  color: $dark-blue;
}

.tile p {
  text-align: left;
}

.tile .arrow {
  position: absolute;
  bottom: 10px;
  right: 10px;
  font-size: 1.1em;
  text-decoration: none;
}

.full-docs {
  margin-top: 10px;
  font-size: 0.9em;
}

.full-docs summary {
  cursor: pointer;
  font-weight: bold;
  color: #0073e6;
}

.full-docs ul {
  padding-left: 20px;
  margin-top: 5px;
}

.full-docs li {
  list-style-type: none;
}
