.highlights-alternating-images-band {
  .highlights-alternating-inline-block {
    padding: 2rem 0 4rem 0;
  }
  .block-content,
  .block-image{
    align-self: center;
    justify-self: center;
  }
}
