.contributions-band {
  .contrib-block  img {
    width: 150px;
  }

  a.title {
    font-weight: 600;
    display: block;
  }
}
